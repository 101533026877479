import React, { useState } from "react";
import "./Hamburger.css";

export default function Hamburger({ action }) {
  const [isActive, setIsActive] = useState(false);

  function toggleActive() {
    action();
    setIsActive(!isActive);
  }

  return (
    <div
      className={`hamburger-menu ${isActive ? "active" : ""}`}
      onClick={toggleActive}
    >
      <div className={`hamburger-line ${isActive ? "activeLine" : ""}`}></div>
      <div className={`hamburger-line ${isActive ? "activeLine" : ""}`}></div>
      <div className={`hamburger-line ${isActive ? "activeLine" : ""}`}></div>
    </div>
  );
}
