import React from 'react'
import collaborationImg from './collaboration.jpg'
import virtualDebutImg from './virtualdebut.jpg'
import recapImg from './2019_recap.jpg'
import './Press.css'

const articles = [
    {
        title: "Innovation at HackNYU",
        date: "February 28, 2024",
        link: "https://meet.nyu.edu/life/collaboration-and-innovation-take-center-stage-at-hacknyu/",
        img: collaborationImg
    },
    {
        title: "HackNYU's Virtual Debut",
        date: "November 02, 2022",
        link: "https://meet.nyu.edu/life/hacknyus-virtual-debut/",
        img: virtualDebutImg
    },
    {
        title: "A Recap of HackNYU 2019",
        date: "March 11, 2019",
        link: "https://medium.com/hacknyu/a-recap-of-hacknyu-2019-eebd7de5e8f9",
        img: recapImg
    }
]

export default function Press() {

    return (
        <center>
            <div id="press-page">
                <div className="press-header">
                    <h2 className="title">Read More About Us</h2>
                    <p className="curious-text">If you're curious to learn more about HackNYU, here are a few articles that you can look through.</p>
                </div>
                <div className="article-container">
                    <div className="press-box">
                        <img className="img-box" src={collaborationImg} alt="Innovation at HackNYU"></img>
                        <div className="purple-box">
                            <p className="article-title">Innovation at HackNYU</p>
                            <p className="published-text">February 28, 2024</p>
                            <a className="article-link" target="_blank" href="https://meet.nyu.edu/life/collaboration-and-innovation-take-center-stage-at-hacknyu/">Read Here &rarr;</a>
                        </div>
                    </div>
                    <div className="press-box">
                        <img className="img-box" src={virtualDebutImg} alt="Virtual Debut"></img>
                        <div className="purple-box">
                            <p className="article-title">HackNYU's Virtual Debut</p>
                            <p className="published-text">November 02, 2022</p>
                            <a className="article-link" target="_blank" href="https://meet.nyu.edu/life/hacknyus-virtual-debut/">Read Here &rarr;</a>
                        </div>
                    </div>
                    <div className="press-box">
                        <img className="img-box" src={recapImg} alt="A Recap of HackNYU 2019"></img>
                        <div className="purple-box">
                            <p className="article-title">A Recap of HackNYU 2019</p>
                            <p className="published-text">March 11, 2019 </p>
                            <a className="article-link" target="_blank" href="https://medium.com/hacknyu/a-recap-of-hacknyu-2019-eebd7de5e8f9">Read Here &rarr;</a>
                        </div>
                    </div>
                </div>
            </div>
        </center>
    )
}