import React, { useState } from 'react'
import './FAQView.css'

export default function FAQCard({ item, id }) {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    console.log(isActive);
    setIsActive(!isActive);
}

  return (
    <div className="faq-question">
      <button onClick={toggleActive} className={`plus ${isActive ? "active" : ""}`}>+</button>
      <p className="panel-title" onClick={toggleActive}>{item.question}</p>
      <div className="panel-content" >
        <p>{item.answer}&nbsp;
        {item.link ? (
          <a className="faq-link" href={item.link}>{item.linkCaption}</a>
        ) : <span></span>
        }
        </p>
      </div>
    </div>
  )
}
