import React from 'react'
import "./Footer.css"
import ig from "./iglogo.svg"
import linkedin from "./linkedinlogo.svg"
import facebook from "./facebooklogo.svg"
import mail from "./maillogo.svg"

export default function About() {
    return (
        <footer>
            <div className = "icons">
                <div className ="contact-logos">
                    <a href="https://www.instagram.com/hacknyu/"><img src={ig} alt="Access our Instagram"></img></a>
                    <a href="https://www.linkedin.com/company/hacknyu"><img src={linkedin} alt="Access our LinkedIn"></img></a>
                    <a href="https://www.facebook.com/hacknyu/"><img src={facebook} alt="Access our Facebook"></img></a>
                </div>
                <div className="mailing">
                    <p>Have any questions or business inquiries?</p>
                    <p>Email us at <a className="mail-address" href="mailto: hack.support@nyu.edu">hack.support@nyu.edu</a></p>
                </div>
            </div>
            <div className = "conduct">
                <p><a href="https://www.nyu.edu/about/policies-guidelines-compliance/compliance/code-of-ethical-conduct.html">New York University Code of Conduct</a></p>
                <p><a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">Major League Hacking Code of Conduct</a></p>
            </div>
        </footer>
    )
}