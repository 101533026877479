import React from 'react';
import './Gallery.css';
import { Fade } from 'react-awesome-reveal';
import { ImageSlider } from './ImageSlider';
import img1 from './GalleryImages/img1.jpg';
import img2 from './GalleryImages/img2.jpg';
import img3 from './GalleryImages/img3.jpg';
import img4 from './GalleryImages/img4.jpg';

const imageUrls = [img1, img2, img3, img4];

export default function Gallery() {
  return (
    <center>
      <div id='gallery-page'>
        <Fade bottom duration={2000} distance="40px">
            <div className='title'>Gallery</div>
            <div className="description">These are some of the photos taken at our 2023 mini-hackathon!</div>
            <div className='gallery-slider'>
              <ImageSlider imageUrls={imageUrls} />
            </div>
        </Fade>
      </div>
    </center>
  );
}
