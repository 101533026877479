import React from 'react'
import PastSponsors from './PastSponsors'
import SponsorForm from './SponsorForm'
import "./SponsorView.css"

export default function SponsorView() {
  return (
    <center>
    <div className='sponsor-flexbox-container' id='sponsors-page'>
        <PastSponsors/>
        <br/>
        <SponsorForm/>
    </div>
    </center>
  )
}
