import React from 'react';
import styles from './AppLayout.module.css';

import THICK_TRIANGLE_URL from "./../Assets/Patterns/pattern5.svg"
import THIN_TRIANGLE_URL from "./../Assets/Patterns/pattern6.svg"
import THIN_TRIANGLE_2_URL from "./../Assets/Patterns/pattern4.svg"
import THIN_TRIANGLE_3_URL from "./../Assets/Patterns/pattern3.svg"

const PATTERNS = (
<div className={styles.patterns}>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_3_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_3_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_3_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_2_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THICK_TRIANGLE_URL} alt='triangle' className={styles.pattern}></img>
      <img src={THIN_TRIANGLE_3_URL} alt='triangle' className={styles.pattern}></img>
    </div>
)

const AppLayout = React.memo(({children}) => (
  <main className={styles.mainContainer}>
    {PATTERNS}
    {PATTERNS}
    {PATTERNS}
    {children}
  </main>
));

export default AppLayout;

