import React, {useState, useMemo} from 'react'  
import GlowButton from "../GlowButton/GlowButton.js";
import Input from "../Input";
import {useForm} from '../utils/useForm.js';
import {validateText, validateEmail} from '../utils/validations.js';
import {useMailchimpSubscribe} from '../utils/useMailchimpSubscribe.js';
import { Fade } from 'react-awesome-reveal';

// const INITIAL_FORM_FIELDS = [
//   {name: 'fullName', validate: validateText, initialValue: ''},
//   {name: 'email', validate: validateEmail, initialValue: ''},
//   {name: 'company', validate: validateText, initialValue: ''}
// ];

export default function SponsorForm() {
  // const {values, setValue, validate} = useForm(INITIAL_FORM_FIELDS);
  // const {isIdle, subscribe} = useMailchimpSubscribe();

  // const fullName = values['fullName']?.value || '';
  // const isFullNameValid = !values['fullName']?.error;

  // const email = values['email']?.value || '';
  // const isEmailValid = !values['email']?.error;

  // const company = values['company']?.value || '';
  // const isCompanyValid = !values['company']?.error;

  // const onSubmit = async () => {
  //   if (validate()) {
  //     await subscribe(`EMAIL=${email}&COMP=${company}&FULLN=${fullName}`);
  //   }
  // }

  // const form = useMemo(() => !isIdle ? <p>Thank you for your inquiry. We will reach out shortly!</p> : (
  //   <>
  //     <Input type="text" value={fullName} label="Full name" onChange={setValue('fullName')} isValid={isFullNameValid} />
  //     <Input type="email" value={email} label="Email" onChange={setValue('email')} isValid={isEmailValid} />
  //     <Input type="text" value={company} label="Company" onChange={setValue('company')} isValid={isCompanyValid} />
  //     <GlowButton onClick={onSubmit} value="Get Our Sponsorship Package" />
  //   </>
  // ), [isIdle, fullName, isFullNameValid, email, isEmailValid, company, isCompanyValid]);
 
  return (
    <div className="column">
      <Fade direction='left' duration={2000}>
        <div>
            <h2 className="title">Interested in Sponsoring Us?</h2>
            {/* <p style={{'textAlign':'left'}}>For more information, download our <br></br> sponsorship guide here:</p>
            <br></br>
            {form} */}
            <p className="sponsor-description">Please send us your FULL NAME, EMAIL, and COMPANY, and we will contact you shortly. </p>
            <GlowButton className="sponsor-button" onClick={() => window.open("mailto:sponsorship.hack@nyu.edu")} value="Click to Sponsor Us!" />
        </div>
        </Fade>
    </div>
  )
}
