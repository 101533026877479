import React from 'react'
import './FAQView.css'
import FAQCard from './FAQCard'

const faq = [
  {
    question: 'What is HackNYU?',
    answer:
      'HackNYU is a 48-hour world-wide hackathon hosted by NYU students. Come build your dream project, learn from our talks and mentors, and win prizes! HackNYU is free and made possible thanks to our wonderful sponsors and volunteers.',
  },
  {
    question: 'What is HackNYU 2025?',
    answer:
      'Spring 2025 HackNYU will be a 48-hour event hosted by NYU for all college students and high school students.',
  },
  {
    question: 'What are the tracks?',
    answer: 'We have 4 tracks: Gaming and Digital Frenzy, Healthcare and Lifestyle, Mixed Reality and Interactive Media Arts, and Financial Technology and Empowerment!'
  },
  {
    question: 'Where is HackNYU taking place? ',
    answer:
      "It will take place at the Brooklyn Athletic Facility gym at NYU Tandon. You will get more details upon acceptance.",
  },
  {
    question: 'Do I need a team to sign up?',
    answer:
      'No, we will have a team forming opportunity during the hackathon and other ways to form teams beforehand once you are accepted!',
  },
  {
    question: 'Do I need prior experience?',
    answer:
      'No prior experience is needed! A large number of our hackers are first-time hackathon attendees!',
  },
  {
    question: 'Is there a discord? Where do I join the discord?',
    answer: 'Yes, you will be invited to the discord upon admissions.',
  },
  {
    question: 'Will there be food and swag?',
    answer: 'Yes, thanks to our kind and generous sponsors :)',
  },
  // the two are using the generic card to allow for links.
  {
    question: 'Are there rules?',
    answer: 'We follow the MLH Code of Conduct! Be respectful. Harassment and abuse are never tolerated. If you are in a situation that makes you uncomfortable at an MLH Member Event, if the event itself is creating an unsafe or inappropriate environment, or if interacting with a MLH representative or event organizer makes you uncomfortable, please report it using the procedures included in this document. For more info, please read',
    link: 'https://static.mlh.io/docs/mlh-code-of-conduct.pdf',
    linkCaption: 'here'
  },
  {
    question: 'I have more questions...',
    answer: 'Please email us at hack.support@nyu.edu if you have any additional questions!',
  },
];

export default function FAQView() {
  return (
   <div id="faq-page">
    <h2 className="title">Frequently Asked Questions</h2>
    <div className="faq-content">
      {faq.map((q, id)=> (
        <FAQCard item={q} id={id} key={id}/>  
      ))}
    </div>
   </div>
  )
}

