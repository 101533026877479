import React, { useState } from 'react';
import Slider from 'react-slick';
import "./Past-Iterations.css";
import "./About.css";
import "./Past-Winners.css";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";

import winner2017 from '../Assets/PastWinners/IDYourFace_2017.jpg';
import winner2018 from '../Assets/PastWinners/IdeaCloud_2018.png';
import winner2019 from '../Assets/PastWinners/ShiftAssist_2019.jpg';
import winner2020 from '../Assets/PastWinners/Change_2020.jpg';
import winner2022 from '../Assets/PastWinners/Parampower_2022.jpg';
import winner2023 from '../Assets/PastWinners/Soteria_2023.png';
import winner2023mini from '../Assets/PastWinners/PerfectDay_2023_mini.jpeg';

const textMappings = [
    { year: "2017", "name": "IDYourFace", caption: "Assistive Technology", originalImage: winner2017, originalLink: "https://devpost.com/software/oh-i-member", hackathon_link: "https://hacknyu2017.devpost.com/project-gallery"},
    { year: "2018", "name": "IdeaCloud", caption: "Educational Technology", originalImage: winner2018, originalLink: "https://devpost.com/software/ideacloud-oqmx2z", hackathon_link: "https://hacknyu2018.devpost.com/project-gallery"},
    { year: "2019", "name": "ShiftAssist", caption: "Health & Well-Being", originalImage: winner2019, originalLink: "https://devpost.com/software/shiftassist", hackathon_link: "https://hacknyu2019.devpost.com/project-gallery"},
    { year: "2020", "name": "Change", caption: "Sustainability", originalImage: winner2020, originalLink: "https://devpost.com/software/change-o9yabw", hackathon_link: "https://hacknyu-2020.devpost.com/project-gallery"},
    { year: "2022", "name": "Parampower", caption: "Inclusion", originalImage: winner2022, originalLink: "https://devpost.com/software/parampower", hackathon_link: "https://hacknyu-2022.devpost.com/project-gallery"},
    { year: "2023", "name": "Soteria", caption: "Best NYU Student Hack", originalImage: winner2023, originalLink: "https://devpost.com/software/soteria-1kp9s7", hackathon_link: "https://hacknyu-2023.devpost.com/project-gallery"},
    { year: "2023 Mini", "name": "Perfect Day", caption: "People's Choice Hack", originalImage: winner2023mini, originalLink: "https://devpost.com/software/perfect-day-game", hackathon_link: "https://mini-hacknyu-2023.devpost.com/project-gallery"}
];

function SampleNextArrow({ onClick }) {
    return (
        <div className='arrow arrow-right custom-arrow' onClick={onClick}>
            <VscChevronRight />
        </div>
    );
}

function SamplePrevArrow({ onClick }) {
    return (
        <div className='arrow arrow-left custom-arrow' onClick={onClick}>
            <VscChevronLeft />
        </div>
    );
}

function PastWinners() {
    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        beforeChange: (current, next) => setSlideIndex(next),
        centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: (dots) => (
            <div>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: (current, next) => (
            <div className={current === slideIndex ? 'dot dot-active' : 'dot'}>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                }
            }]
    };

    return (
        <center>
            <div className="pi-container" id='past-winners-page'>
                <h2 className="section-title title">Past Winners</h2>

                <div className="i-text-container">
                    Look through the amazing projects completed during the past years by many talented participants.
                </div>

                <Slider {...settings}>
                {textMappings.map((item, index) => (
                    <div key={index} className="press-box-container">
                        <div className="winner-box">
                            <img className="winner-img" src={item.originalImage} alt={item.name} />
                            <div className="winner-desc">
                                <a className="project-link" href={item.originalLink} target="_blank" rel="noopener noreferrer">{item.name}</a>
                                <p style={{ fontSize: '21px' }}>{item.year}</p>
                                <span className="category">{item.caption}</span>
                                <a className="devpost-link" href={item.hackathon_link} target="_blank" rel="noopener noreferrer">More Winners & Projects &rarr;</a>
                                <br />
                            </div>
                        </div>
                    </div>
            ))}
                </Slider>
            </div>
        </center>
    );
}

export default PastWinners;