import React from 'react'
import "./About.css"
import about_img from "./about.jpg"
import { Fade } from 'react-awesome-reveal'

export default function About() {
    return (
    <>
    <Fade bottom duration={2000} distance="40px">
    <center>
    {/* ----ABOUT HACKNYU---- */}

    <div className="container" id='about-page'>

        <h2 className="title">
        About HackNYU
        </h2>

        <p className="text-container">
            HackNYU is a global hackathon that brings together passionate, inspired, innovative, and talented students from across the globe to find solutions for a variety of challenges.    
        </p>

        <p className="text-container">
            We are a student-led organization at NYU and we strive to curate a warm and welcoming community for tech students as well as create opportunities for people of all backgrounds to excel.
        </p>

        <div>
            <img className="img" src={about_img}  alt="HackNYU 2023, students working on project together" ></img>
        </div>

        <p className="img-label">
            mini-HackNYU 2023
        </p>
    </div>

    </center>
    </Fade>
    </>
    )
}
