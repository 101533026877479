import React, { useState } from "react";
import "./Navbar.css";
import logo1 from "./logo1.svg";
import mlhLogo from "./mlh-logo-white.svg";
import { Link } from "react-scroll";
import Hamburger from "./Hamburger";

export default function Navbar() {
  const [showingMenuInMobile, setMenuState] = useState(false);
  return (
    <nav className="navbar">
      <div className="left-section">
        <a href="https://hacknyu.org/2023" target="_blank" rel="noreferrer">
          <img src={logo1} alt="HackNYU Logo" className="logo"></img>
        </a>
      </div>
      <div className={showingMenuInMobile ? "menu" : "menu invisibleInMobile"}>
        <Link
          className="menu-item"
          to="about-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          ABOUT
        </Link>
        <Link
          className="menu-item"
          to="past-iterations-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          PAST ITERATIONS
        </Link>
        <Link
          className="menu-item"
          to="past-winners-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          PAST WINNERS
        </Link>
        <Link
          className="menu-item"
          to="gallery-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          GALLERY
        </Link>
        <Link
          className="menu-item"
          to="sponsors-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          SPONSORS
        </Link>
        <Link
          className="menu-item"
          to="press-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          PRESS
        </Link>
        <Link
          className="menu-item"
          to="faq-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          FAQ
        </Link>
        <Link
          className="menu-item"
          to="team-page"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          TEAM
        </Link>
      </div>
      <img src={mlhLogo} alt="MLH Logo" id="mlh-logo"></img>
      <div className="right-section">
        <Hamburger action={() => setMenuState(!showingMenuInMobile)} />
      </div>
    </nav>
  );
}
