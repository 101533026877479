import React, {useState, useEffect} from 'react';

/*
  * fields {
  *   name: string;
  *   validate: (string) => boolean;
  *   initialValue: string;
  * }[]
  *
  */

export const useForm = (fields) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const initialFields = fields.reduce((acc, curr) => {
      acc[curr.name] = {value: curr.initialValue, error: false};
      return acc;
    }, {});

    setValues(initialFields);
  }, []);

  const setValue = (name) => (value) => {
    setValues((prev) => ({...prev, [name]: {...prev[name], value}}))
  }

  const validate = () => {
    let allValid = true;
    fields.forEach(({name, validate}) => {
      const isValid = validate(values[name].value);
      if (!isValid) allValid = false;
      setValues((prev) => ({...prev, [name]: {...prev[name], error: !isValid}}));
    });
    return allValid;
  }

  return {
    values,
    validate,
    setValue
  };
}

