import React, {useState} from 'react'
import "./Hero.css"
import "./Button.css"
import arrowImg from './arrow.svg';
import GlowButton from '../GlowButton/GlowButton';
import Input from '../Input';
import {useForm} from '../utils/useForm.js';
import {useMailchimpSubscribe} from '../utils/useMailchimpSubscribe.js';
import {validateEmail} from '../utils/validations.js';
import {Link} from 'react-scroll';
import { Fade } from 'react-awesome-reveal';

const INITIAL_FORM_FIELDS = [{
  name: 'email',
  validate: validateEmail,
  initialValue: '',
}]

export default function Hero() {
  const {getFieldValue, isFieldValid, validate, setValue, values} = useForm(INITIAL_FORM_FIELDS);
  const {isIdle, subscribe} = useMailchimpSubscribe();

  const onSubmit = async () => {
    if (validate()) {
      await subscribe(`EMAIL=${values['email']?.value}`);
    }
  }

  return (
    <Fade bottom duration={2000} distance="40px">
    <header id='hero-page'>
      <div className='top-title-container'>
        <span className='top-title glowOutside'>Hack</span>
        <span className='top-title nyu-purple glowOutside'>NYU</span>
      </div>
      <h1 >A Global Hackathon Hosted by NYU</h1>
      <p className='subtitle'>
        Get ready for a new theme, fun tracks, and more prizes!
      </p>
      <center>
      <div className='hero-buttons-container'>
        <GlowButton value='Coming Soon'/>
      </div>
      </center>
      {/* <p className="subtitle">
      Sign up for our mailing list for updates on HackNYU 2023 and more! 
      <br></br>
      Get ready for a new theme, fun tracks, and more prizes. 
      </p>
      <div className ="sign-up-div">
        <button className="sign-up-button"><a href="https://jl8k0sn4vwg.typeform.com/HackNYU2023">HACKER SIGN UP</a></button>
        <button className="sign-up-button"><a href="https://jl8k0sn4vwg.typeform.com/HackNYU2023-Vol">VOLUNTEER SIGN UP</a></button>
      </div> */}
      <Link to="about-page" spy={true} smooth={true} offset={-100} duration={500}>
        <img src={arrowImg} className="arrow heroArrow" alt="arrow down" />
      </Link>
    </header> 
    </Fade>
  )
}
