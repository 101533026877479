import React from 'react'
import pastSponsorsImg from "./SponsorsAssets/past_sponsors.png"
import "./PastSponsors.css"

export default function PastSponsors() {
  return (
    <div className="column">
        <h2 className="title">Past Sponsors</h2>
        <p className="sponsor-description">HackNYU is made possible thanks to our wonderful sponsors and volunteers. <br/><br/>Here are a few of our past sponsors!</p>
        <img className="pastSponsorImg" alt="our past sponsors" src={pastSponsorsImg}/>
    </div>
  )
}
