import React from 'react'
import "./GlowButton.css"

const GlowButton = React.memo(({onClick, value}) => (
  <div className="glowButton">
    <div onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {value}
    </div>
  </div>
));

export default GlowButton;

