import React from 'react'
import teamInfo from './teamInfo.json'
import SingleProfile from './SingleProfile'
import './TeamDisplay.css'
import { Fade } from 'react-awesome-reveal'

export default function TeamDisplay() {
  return (
    <Fade right duration={2000} distance='40px'>
    <div id='team-page'>
      <h2 className = 'title'>Meet The Team</h2>
      <div className='team-container'>
        {teamInfo.map((profile, i)=>(
          profile.isDirector === 'Yes' ? <SingleProfile key={i} profile={profile} isDirector={true}/> : <SingleProfile key={i} profile={profile} isDirector={false}/>
        ))}
      </div>
    </div>
    </Fade>
  )
}
