import { useState } from "react";
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import "./ImageSlider.css";

export function ImageSlider({ imageUrls }) {
  const [imageIndex, setImageIndex] = useState(0);
  const captions = ["Check-In", "Breakfast (MUSH Oats)", "More MUSH Oats :)", "Getting Ready for Opening Ceremony"]

  function showNextImage() {
    setImageIndex(index => (index === imageUrls.length - 1 ? 0 : index + 1));
  }

  function showPrevImage() {
    setImageIndex(index => (index === 0 ? imageUrls.length - 1 : index - 1));
  }

  return ( 
    <>
    <div className="img-slider-container">
      {imageUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Slide ${index}`}
          className={`img-slider-img ${index === imageIndex ? 'active' : ''}`}
        />
      ))}
      <button onClick={showPrevImage} className="img-slider-btn" style={{ left: 0 }}>
        <VscChevronLeft className="icon" />
      </button>
      <button onClick={showNextImage} className="img-slider-btn" style={{ right: 0 }}>
        <VscChevronRight className="icon" />
      </button>
    </div>
    <div className="caption">{captions[imageIndex]}</div>
    </>
  );
}
