import React, {useMemo, useState} from 'react';
import fetchJsonp from 'fetch-jsonp';

// this is a demo mailing list url
// change it to the legit one later (?)
// const POST_URL = `https://hacknyu.us21.list-manage.com/subscribe/post-json?u=79e14321962e8ba2dfc1cddf7&id=304f7d0c67`;
const POST_URL = `https://hacknyu.us10.list-manage.com/subscribe/post-json?u=4696c5232113dcab451e2b4104e5abb9&id=0d3a285429`;
// 4696c5232113dcab451e2b4104e5abb9-us10
//0d3a285429


const IDLE = "IDLE";
const LOADING = "LOADING";
const ERROR = "ERROR";
const SUCCESS = "SUCCESS";

export const useMailchimpSubscribe = () => {
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const subscribe = async (params) => {
    setStatus(LOADING);
    console.log("hi tjere")
    await fetchJsonp(POST_URL + "&" + params)
      .then((res) => {
        console.log("hi")
        return res.json();
      })
      .then((json) => {
        setStatus(SUCCESS);
        console.log(json);
      })
      .catch((e) => setStatus(ERROR));
  }

  return {
    isLoading: status === LOADING,
    isError: status === ERROR,
    isSuccess: status === SUCCESS,
    isIdle: status === IDLE,
    subscribe
  }
}

