import React from 'react'
import './SingleProfile.css'

export default function SingleProfile({profile, isDirector}) {
  const imageClass = isDirector ? 'profile-image director' : 'profile-image' 
  return (
    <div className='profile-container'>
      <a className={imageClass} {...(profile.link !== '' ? { href: profile.link } : {})} target='_blank' rel='noopener noreferrer'>
        <img style={{'width': '100%', 'height': '100%', 'objectFit': 'cover'}} src={require(`../Assets/TeamProfiles/${profile.imageName}`)} alt={profile.imageName} /> 
       </a>
       <h3 className='profile-name'>{profile.name}</h3>
       <p className='profile-title'>{profile.title}</p>
    </div>
  )
}
